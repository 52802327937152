nav {
    background-color: #2d9cdb;
    -moz-box-shadow: inset 0 -15px 10px -15px #000000;
    -webkit-box-shadow: inset 0 -15px 10px -15px #000000;
    box-shadow: inset 0 -15px 10px -15px #000000;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #2d9cdb;
    gap: 15px;
}

.navTitle {
    font-size: 1.5rem;
}

.inputs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.inputs::-webkit-scrollbar {
    display: none;
}

.inputs div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}


.navEvent, .navSave {
    display: none;
}

input[type="button"] {
    padding: 5px 12px;
    border: none;
    border-radius: 15px;
    background: white;
    color: #2d9cdb;
    font-family: "lato";
    font-weight: 700;
    height: 30px;
}

input[type="file"] {
    background: white;
    color: #2d9cdb;
    border-radius: 15px;
    font-family: "lato";
    height: 30px;
    cursor: pointer;
}

input[type="file"]::-webkit-file-upload-button {
    background: #176794;
    color: #ffffff;
    height: 30px;
    border: none;
    border-radius: 0 15px 15px 0;
    padding: 5px 12px;
}

.uploading {
    margin-right: 4px;
    color: white;
}

h1 {
    color: white;
}

label[for="format-input"] {
    color: white;
    font-size: 1rem;
    font-weight: 700;
}

#format-input {
    padding: 5px;
    height: 30px;
    border-radius: 15px;
    border-style: hidden;
    font-size: large;
}

#format-input:focus {
    outline: none;
}

/* Easter Egg */
.logo > span:hover {
    animation: rotate 0.5s;
}

@keyframes rotate {
    0% { transform: rotate(10deg);}
    20% { transform: rotate(20deg);}
    40% { transform: rotate(0deg);}
    60% { transform: rotate(-10deg);}
    80% { transform: rotate(-20deg);}
    100% {transform: rotate(0deg);}
}

#deleteCowOnUpdate {
    display: none;
    padding: 0 1em 0 1em;
    justify-content: center;
    align-items: center;
}

#deleteCowOnUpdate label {
    color: white;
    margin-right: 1em;
}

.question {
    border: 2px solid white;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}
.question > span {
    color: white;
    font-size: 1.2rem;
    font-weight: 700;
    cursor: pointer;
}