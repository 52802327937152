input[type=date] {
    font-family: "lato", sans-serif;
}

input[type=date][value=''] {
    color: gray;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.spreadsheet {
    display: flex;
    flex-grow: 1;
    width: 100vw;
}

.spreadsheet > div {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100vw;
}

.empty-table {
    text-align: center;
    margin-top: 50px;
    color: #2d9cdb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    width: 100%;
}

.duplicated-value {
    background-color: rgba(200, 61, 255, 0.6);
}

.missing-value {
    background-color: rgba(255,0,0,0.6);
}

.updated-value {
    background-color: rgba(199,184,26,0.6);
}

.new-cow {
    background-color: rgba(132,187,114,0.6);
}

.no-change {
    background-color: white;
}

.highlight {
    animation: highlightItem 0.75s infinite;
}

@keyframes highlightItem {
    0% { box-shadow: 'none'; }
    50% { box-shadow: 0 0 10pt yellow; }
    100% { box-shadow: 'none'; }
}