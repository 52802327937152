@import url(https://use.typekit.net/lna6dbu.css);
body {
  margin: 0;
  font-family: "lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main { 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  flex-grow: 1;
}
nav {
    background-color: #2d9cdb;
    box-shadow: inset 0 -15px 10px -15px #000000;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #2d9cdb;
    grid-gap: 15px;
    gap: 15px;
}

.navTitle {
    font-size: 1.5rem;
}

.inputs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.inputs::-webkit-scrollbar {
    display: none;
}

.inputs div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}


.navEvent, .navSave {
    display: none;
}

input[type="button"] {
    padding: 5px 12px;
    border: none;
    border-radius: 15px;
    background: white;
    color: #2d9cdb;
    font-family: "lato";
    font-weight: 700;
    height: 30px;
}

input[type="file"] {
    background: white;
    color: #2d9cdb;
    border-radius: 15px;
    font-family: "lato";
    height: 30px;
    cursor: pointer;
}

input[type="file"]::-webkit-file-upload-button {
    background: #176794;
    color: #ffffff;
    height: 30px;
    border: none;
    border-radius: 0 15px 15px 0;
    padding: 5px 12px;
}

.uploading {
    margin-right: 4px;
    color: white;
}

h1 {
    color: white;
}

label[for="format-input"] {
    color: white;
    font-size: 1rem;
    font-weight: 700;
}

#format-input {
    padding: 5px;
    height: 30px;
    border-radius: 15px;
    border-style: hidden;
    font-size: large;
}

#format-input:focus {
    outline: none;
}

/* Easter Egg */
.logo > span:hover {
    -webkit-animation: rotate 0.5s;
            animation: rotate 0.5s;
}

@-webkit-keyframes rotate {
    0% { -webkit-transform: rotate(10deg); transform: rotate(10deg);}
    20% { -webkit-transform: rotate(20deg); transform: rotate(20deg);}
    40% { -webkit-transform: rotate(0deg); transform: rotate(0deg);}
    60% { -webkit-transform: rotate(-10deg); transform: rotate(-10deg);}
    80% { -webkit-transform: rotate(-20deg); transform: rotate(-20deg);}
    100% {-webkit-transform: rotate(0deg);transform: rotate(0deg);}
}

@keyframes rotate {
    0% { -webkit-transform: rotate(10deg); transform: rotate(10deg);}
    20% { -webkit-transform: rotate(20deg); transform: rotate(20deg);}
    40% { -webkit-transform: rotate(0deg); transform: rotate(0deg);}
    60% { -webkit-transform: rotate(-10deg); transform: rotate(-10deg);}
    80% { -webkit-transform: rotate(-20deg); transform: rotate(-20deg);}
    100% {-webkit-transform: rotate(0deg);transform: rotate(0deg);}
}

#deleteCowOnUpdate {
    display: none;
    padding: 0 1em 0 1em;
    justify-content: center;
    align-items: center;
}

#deleteCowOnUpdate label {
    color: white;
    margin-right: 1em;
}

.question {
    border: 2px solid white;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}
.question > span {
    color: white;
    font-size: 1.2rem;
    font-weight: 700;
    cursor: pointer;
}
#farm-select {
    min-width: 150px;
    height: 30px;
    padding: 4px;   
    font-size: 16px;
    border-radius: 15px;
    outline: none;
}

#cowCount {
    margin-left: 10px;
    color: white;
}
.form-container {
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
    width: 300px;
    height: 300px;
    box-shadow: 0px 0px 36px 8px rgba(0,0,0,0.25);
}

.form-container h2 {
    font-size: 24px;
    font-weight: bold;
    margin: 20px;
    color: #2d9cdb;
}

.form {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}

.form-input {
    height: 36px;
    width: 240px;
    border: none;
    background-color: white;
    font-size: 16px;
    padding: 0 5px;
}

.form-input:hover{
    background-color: #2d9bdb2b;
    border:none;
}

.form-input:focus-visible{
    outline: none;
    border:none;
}

.form-lifecycle { 
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 100%;
    height: 150px;
}

.form-lifecycle h3 {
    color: #2d9cdb;
    text-align: center;
}

.form-lifecycle .error {
    color: #c10000;
}

.form-lifecycle .success {
    color: #009708;
}

#form-mail {
    border-bottom: 1px solid #2d9cdb;
}


#form-button {
    background-color: #2d9cdb;
    color: white;
    font-weight: bold;
    border-radius: 10px;
}

#form-button:hover {
    background-color: #0d4b6e;
}
.modal-container { 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}
input[type=date] {
    font-family: "lato", sans-serif;
}

input[type=date][value=''] {
    color: gray;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.spreadsheet {
    display: flex;
    flex-grow: 1;
    width: 100vw;
}

.spreadsheet > div {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100vw;
}

.empty-table {
    text-align: center;
    margin-top: 50px;
    color: #2d9cdb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    width: 100%;
}

.duplicated-value {
    background-color: rgba(200, 61, 255, 0.6);
}

.missing-value {
    background-color: rgba(255,0,0,0.6);
}

.updated-value {
    background-color: rgba(199,184,26,0.6);
}

.new-cow {
    background-color: rgba(132,187,114,0.6);
}

.no-change {
    background-color: white;
}

.highlight {
    -webkit-animation: highlightItem 0.75s infinite;
            animation: highlightItem 0.75s infinite;
}

@-webkit-keyframes highlightItem {
    0% { box-shadow: 'none'; }
    50% { box-shadow: 0 0 10pt yellow; }
    100% { box-shadow: 'none'; }
}

@keyframes highlightItem {
    0% { box-shadow: 'none'; }
    50% { box-shadow: 0 0 10pt yellow; }
    100% { box-shadow: 'none'; }
}
