#farm-select {
    min-width: 150px;
    height: 30px;
    padding: 4px;   
    font-size: 16px;
    border-radius: 15px;
    outline: none;
}

#cowCount {
    margin-left: 10px;
    color: white;
}