.form-container {
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
    width: 300px;
    height: 300px;
    -webkit-box-shadow: 0px 0px 36px 8px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 0px 36px 8px rgba(0,0,0,0.25);
    box-shadow: 0px 0px 36px 8px rgba(0,0,0,0.25);
}

.form-container h2 {
    font-size: 24px;
    font-weight: bold;
    margin: 20px;
    color: #2d9cdb;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.form-input {
    height: 36px;
    width: 240px;
    border: none;
    background-color: white;
    font-size: 16px;
    padding: 0 5px;
}

.form-input:hover{
    background-color: #2d9bdb2b;
    border:none;
}

.form-input:focus-visible{
    outline: none;
    border:none;
}

.form-lifecycle { 
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 100%;
    height: 150px;
}

.form-lifecycle h3 {
    color: #2d9cdb;
    text-align: center;
}

.form-lifecycle .error {
    color: #c10000;
}

.form-lifecycle .success {
    color: #009708;
}

#form-mail {
    border-bottom: 1px solid #2d9cdb;
}


#form-button {
    background-color: #2d9cdb;
    color: white;
    font-weight: bold;
    border-radius: 10px;
}

#form-button:hover {
    background-color: #0d4b6e;
}